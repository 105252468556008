import React, { useEffect } from 'react'
import Navigation from '../components/Navigation'

const ShowcasePage = () => {
  useEffect(() => {
    var loadScript = function(src) {
      var tag = document.createElement('script')
      tag.async = false
      tag.src = src
      document.getElementById('showcase').appendChild(tag)
    }
    loadScript(
      '//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS91ay9lbiZtPUlTRSZjYz16YSZwPTEtMTBQMUktMTI1MjI'
    )
  }, [])

  return (
    <>
      <Navigation />
      <div id="showcase"></div>
    </>
  )
}

export default ShowcasePage
